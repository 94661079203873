import React from 'react'

import { useUsersCountryAccessGroups } from '@/lib/auth/use-users-country-access-groups'
import { useTranslation } from '@/lib/i18n'
import { Box, Typography } from '@mui/material'

import { Dot } from '../../dot'

import { translateCountryAccessGroup } from '../../../const/enum/country-access-group'

export const CountryAccessMenu: React.FC = () => {
    const usersCountryAccessGroups = useUsersCountryAccessGroups()
    const { t } = useTranslation('common')

    if (usersCountryAccessGroups.length === 0) {
        return null
    }

    return (
        <Box>
            <Typography variant="body3" color="dark.simple">{`${t('user')} ${t('enum.CountryAccessGroup.title')}:`}</Typography>
            {Object.values(usersCountryAccessGroups).map(({ countryAccessGroup, businessUnit }) => (
                <Box key={countryAccessGroup} marginLeft={2} display="flex" alignItems="center" gap={0.5}>
                    <Dot />
                    <Typography variant="body3" color="dark.simple">{`${translateCountryAccessGroup(t, countryAccessGroup)} ${
                        businessUnit ? t(`enum.BusinessUnit.${businessUnit}`) : ''
                    }`}</Typography>
                </Box>
            ))}
        </Box>
    )
}
